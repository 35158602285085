/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0D0033;
}

p, div, span, ion-grid, section {
    color: white;
}

ion-col {
  max-width: none !important;
  width: auto !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: black !important;
}

ion-content {
  --ion-background-color:#0D0033;
  font-family: Poppins !important;
  font-weight: 500 !important;
}

ion-content > {
    max-width: 415px;
    min-width: 340px;
    margin: auto !important;
}

section {
    max-width: 415px;
    min-width: 340px;
    margin: auto;
}

ion-header{
    font-family: Poppins !important;
    font-weight: 500 !important;
    min-width: 340px;
    max-width: 415px;
    margin: auto;
}

ion-toggle {
  --background: theme(colors.neutral.800);
  --background-checked: theme(colors.primary.500);

  --handle-background: white;
  --handle-background-checked: white;
}


/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';
